<template>
    <footer>
        <img src="@/assets/icon-neonaco-white.png" alt="icon neonaco" width="100">
        <img src="@/assets/text-neonaco-white.png" alt="icon neonaco" width="200">
        <p style="color: white;">© 2023 Tous droits réservés</p>
        <router-link to="/legal" style="color: white;">Mentions legales</router-link>
    </footer>
</template>
<script>
export default {

}
</script>
<style lang="scss">
@import "../scss/main.scss";

footer {
    width: 100vw;
    background-color: $primary;
    padding: 20px 0;


    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>