<template>
    <div class="position-sticky fixed-top">
        <div style="position: absolute; width: 100vw;">

            <div id="navbar">
                <a href="/">
                    <img src="@/assets/text-neonaco.png" alt="" width="200">
                </a>
                <div id="burger">
                    <div class="burger-line"></div>
                    <div class="burger-line"></div>
                    <div class="burger-line"></div>
                </div>
            </div>
            <nav id="nav">
                <div id="link-container">
                    <li>
                        <router-link to="/">Accueil</router-link>
                    </li>
                    <li>
                        <router-link to="/videos">Nos Vidéos</router-link>
                    </li>
                    <li>
                        <router-link to="/contact">Contact</router-link>
                    </li>
                    <div style="margin-top: 50px;">
                        <li>
                            <router-link class="navli-sec" to="/legal">Mentions légales</router-link>
                        </li>
                        <!-- <li>
                            <router-link class="navli-sec" to="/contact">Politique de confidentalité</router-link>
                        </li> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isNavOpen: false
        }
    },
    mounted() {
        let nav = document.getElementById("nav")
        nav.style.display = "none"
        document.getElementById("burger").addEventListener("click", () => {
            this.isNavOpen ? nav.style.display = "none" : nav.style.display = "block"
            this.isNavOpen = !this.isNavOpen
        })

        nav.addEventListener("click", () => {
            this.isNavOpen ? nav.style.display = "none" : nav.style.display = "block"
            this.isNavOpen = !this.isNavOpen
        })
    }
}
</script>

<style lang="scss">
@import "../scss/main.scss";

#navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 992px) {
        padding: 10px 20px;
    }

    padding: 10px 200px;

    background-color: white;
}

#nav {
    background-color: #0000005d;
    z-index: 10;
    width: 100%;
    top: 0;
    height: 100vh;

    animation: nav_in 0.3s forwards;

    @keyframes nav_in {
        from {
            background-color: transparent;
        }

        to {
            background-color: #0000005d;
        }
    }

    #link-container {
        background-color: white;
        height: 100%;
        float: right;
        text-align: left;

        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        font-size: 20px;
        width: 40%;
        padding-left: 150px;
        letter-spacing: 2px;

        @media (max-width: 992px) {
            padding-left: 20px;
            align-items: center;
            font-size: 30px;
            width: 100%;
        }

        animation: nav_link_in 0.3s forwards;

        @keyframes nav_link_in {
            from {
                margin-right: -100%;
            }

            to {
                margin-right: 0;
            }
        }

        .navli-sec {
            color: #a6a6a6;
            font-size: 10px;
        }

        li {
            list-style: none;
            margin-bottom: 10px;

            a {
                color: rgba(228, 27, 41, 0.75);
                text-decoration: none;

                &.router-link-exact-active {
                    color: $primary;
                    font-weight: bold;
                }
            }
        }
    }
}

#burger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 35px;
    height: 20px;
    cursor: pointer;

    .burger-line {
        border-radius: 20px;
        background-color: $primary;
        height: 3px;
        width: 100%;
    }
}</style>