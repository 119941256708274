import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/videos',
    name: 'videos',
    component: () => import('../views/VideosView.vue'),
    children: [
      {
        path: '',
        name: 'sante',
        component: () => import('../views/videos/SanteVideos.vue'),
      },
      {
        path: '/videos/performance',
        name: 'performance',
        component: () => import('../views/videos/PerformanceVideos.vue'),
      },
      {
        path: '/videos/sport',
        name: 'sport',
        component: () => import('../views/videos/SportVideos.vue'),
      }
    ]
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('../views/LegalView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
