<template>
  <NavBar />
  <router-view />
  <FooterComp />
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterComp from './components/FooterComp.vue'
export default {
  components: {
    NavBar,
    FooterComp
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}

#app {
  font-family: system-ui;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}
</style>
